// @flow

import React from 'react'
import { Link } from 'gatsby'

import styles from './TopicList.module.scss'

type DefinitionProps = {|
  slug: string,
  subject: string,
  title: string,
|}

type Props = {|
  subject: string,
  definitions: Array<DefinitionProps>,
  itemLink: string => string,
|}

export default function TopicList({ subject, definitions, itemLink }: Props) {
  return (
    <div>
      <h3 className={styles.heading}>{subject}</h3>
      <ul className={styles.linkList}>
        {definitions.map((def, i) => {
          return (
            <li key={i}>
              <Link to={itemLink(def.slug)} className={styles.linkListItem}>
                {def.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
