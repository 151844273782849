// @flow

import React from 'react'
import { useIntl } from 'react-intl'

import { linkToVocabPost } from 'src/links'

import TopicList from './TopicList'

type Props = {|
  subject: string,
  definitions: Array<Object>,
|}

export default function TopicListVocab(props: Props) {
  const { locale } = useIntl()

  return (
    <TopicList itemLink={slug => linkToVocabPost(locale, slug)} {...props} />
  )
}
