// @flow

import React, { type Node } from 'react'
import classnames from 'classnames'

import styles from './TopicWrapper.module.scss'

type Props = {|
  children: Array<Node>,
|}

export default function TopicWrapper({ children }: Props) {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.oneColumn]: children.length === 1,
        [styles.twoColumns]: children.length === 2,
      })}
    >
      {children}
    </div>
  )
}
