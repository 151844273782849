// @flow

import React from 'react'
import { useIntl } from 'react-intl'

import { linkToDefinitionsPost } from 'src/links'

import TopicList from './TopicList'

type Props = {|
  subject: string,
  definitions: Array<Object>,
|}

export default function TopicListDefinitions(props: Props) {
  const { locale } = useIntl()

  return (
    <TopicList
      itemLink={slug => linkToDefinitionsPost(locale, slug)}
      {...props}
    />
  )
}
